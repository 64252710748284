type Props = {
    src?: string | undefined;
    hasMirrorEffect?: boolean;
    overlay?: {
        source: string;
        ancherPoint?: "object-center" | "object-top" | "object-bottom"
    }
}

const SideScreenImage = ({ src, overlay, hasMirrorEffect = false }: Props) => {
    return <div className="w-full h-full bg-white justify-center items-center flex flex-col relative overflow-hidden">
        {hasMirrorEffect && <div className="w-full h-full overflow-hidden">
            <img src={src} className="object-cover blur-lg object-top w-full h-full scale-[1.03] z-0" alt="signup_image" />
        </div>}
        <div className="absolute w-full h-full z-10 top-0 left-0 right-0 bottom-0 items-center justify-center felx flex-col">
            {src && <img src={src} className={`${hasMirrorEffect ? "object-contain": "object-cover" } object-center h-full w-full`} alt="signup_image" />}
        </div>
        {overlay && <div className="absolute w-full h-full z-10 top-0 left-0 right-0 bottom-0 items-center justify-center felx flex-col">
            <img src={overlay.source} className={`object-contain ${overlay.ancherPoint ?? "object-bottom"} h-full w-full`} alt="signup_image" />
        </div>}
        <div className="hidden object-center  object-top object-bottom"/>
    </div>
}

export default SideScreenImage;